<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import Dropdown from "vue-simple-search-dropdown";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import Swal from "sweetalert2";

/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      desiredExperienceOptions: [],
      success: false,
    };
  },
  components: {
    Navbar,

    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
        let exps = this.experiences;

        exps.map((exp) => {
          let { id, career_level: value } = exp;

          this.desiredExperienceOptions.push({ id, value });
        });

        console.log("My val", this.desiredExperienceOptions);
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 pagech1">
            <div class="blog_header pl-0">
              <div class="col-lg-12 p-0 w-100">
                <div class="blog_back">
                  <i class="fas fa-long-arrow-alt-left p-1"></i>
                  <router-link to="/career-resources"
                    >Back to blog list</router-link
                  >
                </div>
              </div>
              <h2 class="m_title">Changing Careers</h2>
              <span class="m_title">by: job4u.pk (3 min read)</span>
            </div>

            <div class="p_about2">
              Changing careers can feel daunting and overwhelming for anyone.
              Luckily, we’re here to help, with an easy, approachable method to
              shifting fields, and how to make the best decision from the
              multitude of options available to you.
            </div>

            <div class="p_about2">
              Before you start the shift process, make sure you’re making the
              right call – you know that you want to leave, but don’t fall into
              the trap of picking the earliest available option! There are a lot
              of different options available, so make sure to find the one you
              can actually see yourself sustainably working in. That may mean
              staying in the role you want to leave a little longer, or even
              taking a break from working, while you search, but the payoff is
              well worth it.
            </div>

            <div class="p_about2">
              One surefire way to narrow your choices and make the right call is
              to identify what isn’t working for you right now – what don’t you
              like about your current path? What particular facets of your field
              are pushing you to leave? A good way to do so is to look at how
              you feel – physically and emotionally – throughout the workday.
              Look at what triggers you to identify what you can’t work with.
              Having found this, you can look at jobs, openings, or fields that
              avoid, or at least mitigate, what you don’t like, and can find a
              role best fit for you.
            </div>

            <div class="p_about2">
              Another element to focus on is what you do want – what motivates
              you? Which tasks or elements of the job do you enjoy, and what do
              you value in your workplace? This stage is for you to identify the
              positives, values, and core standards that you consider important,
              so that you can find different career paths and roles that cater
              to what you’re looking for.
            </div>

            <div class="p_about2">
              If you think you’ve found what you’re looking for, or have a few
              options you’re considering, now is the time to speak to
              professionals in those fields. Get a feel for what they do, what
              is expected of them, and what their typical day or week looks
              like. Don’t be afraid to ask questions – the whole point is to
              gain information! See if that sounds like a good fit for you.
              Would you enjoy acting in this role every day? Do the
              responsibilities and duties sound manageable? Look at everything
              entailed, and make sure it’s what you’re looking for.
            </div>

            <div class="p_about2">
              Perhaps one of our most important tips, however, is this – leave
              room for growth. No matter what you pick from the plethora of
              options available, make sure there’s room in the job for you to
              further your journey on the path. Will you be able to work with
              experts for hands-on learning? Can you take courses or seminars to
              enhance your skills? The best way to excel in any career is to
              grow as far as you can in it, so ensure that, whatever you do,
              there’s room for growth in it.
            </div>

            <div class="p_about2">
              Switching to a different career is understandably intimidating,
              but if you work methodically and follow these tips, it’ll be a
              breeze for you!
            </div>
            <br />
            <!-- <hr> -->
          </div>
          <div class="col-lg-4 col-md-4 mt-3">
            <div class="card">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">Want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>

                    <button type="submit" class="btn btn-default join_btn mb-6">
                      join now
                    </button>
                  </div>
                  <div class="col-lg-6 col-2 cv_image_bg">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <!-- on mobile drop down -->

                  <!-- <select v-model="selectedValue">
                  <option class="custom-control-input" disabled value="">Please select one</option>
                  <option class="custom-control-input" v-for="item in experiences" :value="item.career_level" v-bind:key="item.id">{{item.career_level}}</option>
                </select> -->

                  <!-- 
                <div class="card">
                      <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                    <div class="card-body new_multislect mb-4">
                      <multiselect
                        id="multiselectSalary"
                        deselect-label=""
                        select-label=""
                        v-model="experiences"
                        :height="300"
                        :options="desiredExperienceOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Salary Range"
                        label="value"
                        track-by="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div> -->

                  <!--  -->

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
